import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["GetBusinessEntity","GetBusinessEntityReserveSubscriptionPlans","GetBusinessEntityShops","GetBusinessEntityTicketBookSources","GetCustomer","GetCustomers","GetCustomersViewSetting","GetMe","UpdateCustomersViewSetting","hello"]}
export const GqlGetBusinessEntity = (...params) => useGql()('GetBusinessEntity', ...params)
export const GqlGetBusinessEntityReserveSubscriptionPlans = (...params) => useGql()('GetBusinessEntityReserveSubscriptionPlans', ...params)
export const GqlGetBusinessEntityShops = (...params) => useGql()('GetBusinessEntityShops', ...params)
export const GqlGetBusinessEntityTicketBookSources = (...params) => useGql()('GetBusinessEntityTicketBookSources', ...params)
export const GqlGetCustomer = (...params) => useGql()('GetCustomer', ...params)
export const GqlGetCustomers = (...params) => useGql()('GetCustomers', ...params)
export const GqlGetCustomersViewSetting = (...params) => useGql()('GetCustomersViewSetting', ...params)
export const GqlGetMe = (...params) => useGql()('GetMe', ...params)
export const GqlUpdateCustomersViewSetting = (...params) => useGql()('UpdateCustomersViewSetting', ...params)
export const GqlHello = (...params) => useGql()('hello', ...params)