import revive_payload_client_64JL4kT4dR from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_szdcj4uaedooqujbghrglqyeee/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_XtstAx64Jy from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_szdcj4uaedooqujbghrglqyeee/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_m6UVBKV8TB from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_szdcj4uaedooqujbghrglqyeee/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_Oj0dqHD2mO from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_szdcj4uaedooqujbghrglqyeee/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_sSEVDmsspG from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_szdcj4uaedooqujbghrglqyeee/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hIOksrO4em from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_szdcj4uaedooqujbghrglqyeee/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2bibRiGNhM from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_szdcj4uaedooqujbghrglqyeee/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/home/runner/work/cdp-dashboard/cdp-dashboard/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/cdp-dashboard/cdp-dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_wh21EiImAV from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.6_encoding@0.1.13_eslint@8.57.0_ioredis@5_szdcj4uaedooqujbghrglqyeee/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_QTqRjz1kRv from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@20.14.6_encoding@0.1.13_graphql-_bcd7gmzqav4bxbt36sqc34nyyq/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import sentry_client_shVUlIjFLk from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/sentry.client.ts";
import set_graphql_host_client_hEFjBexpur from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/set-graphql-host.client.ts";
import toasts_client_unuLMHjfa9 from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/toasts.client.ts";
export default [
  revive_payload_client_64JL4kT4dR,
  unhead_XtstAx64Jy,
  router_m6UVBKV8TB,
  payload_client_Oj0dqHD2mO,
  navigation_repaint_client_sSEVDmsspG,
  check_outdated_build_client_hIOksrO4em,
  chunk_reload_client_2bibRiGNhM,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wh21EiImAV,
  plugin_QTqRjz1kRv,
  sentry_client_shVUlIjFLk,
  set_graphql_host_client_hEFjBexpur,
  toasts_client_unuLMHjfa9
]